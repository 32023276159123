import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Box from '../components/box';
import PlayScrollLooper from '../components/play-scroll-looper';
import AnimatedText from '../components/animated-text';

function PlayHeader({ data }) {
  return (
    <HeaderBox animate={'visible'}>
      <AnimatedText text={`Play ground`} className="h1" />
      <AnimatedText text={`A whole lot of misc.`} className="h6" />
    </HeaderBox>
  );
}

export default function Template({
  data: {
    prismicPlaygroundPage: { data }
  }
}) {
  return <PlayScrollLooper data={data} header={<PlayHeader data={data} />} />;
}

const HeaderBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 30rem;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-right: 1rem;

  @media ${(props) => props.theme.device.tablet} {
    min-height: 50vw;
    padding-right: 8vw;
  }

  .h1 {
    padding-top: 4rem;

    > span:first-child {
      font-family: 'Sequel_100 Wide W05_95', sans-serif;
      text-transform: uppercase;
      font-style: normal;
      font-size: 0.75em;
    }

    > span:last-child {
      line-height: 1.5;
    }
  }

  .h6 {
    > span {
      padding: 0.2em 0.3em;
    }
  }
`;

/**
 * pageQuery
 */
export const pageQuery = graphql`
  query play($uid: String!) {
    prismicPlaygroundPage(uid: { eq: $uid }) {
      uid
      data {
        title
        body {
          ... on PrismicPlaygroundPageBodyImage {
            id
            primary {
              image {
                alt
                copyright
                url
                thumbnails
                fluid(
                  placeholderImgixParams: { blur: 1600, px: 1 }
                  maxWidth: 1000
                ) {
                  ...GatsbyPrismicImageFluid
                }
                dimensions {
                  height
                  width
                }
              }
              caption
              link {
                url
                target
                type
              }
            }
          }
          ... on PrismicPlaygroundPageBodyVideo {
            id
            primary {
              caption
              video {
                id
                url
              }
              link {
                url
                target
                type
              }
            }
          }
        }
      }
    }
  }
`;
