import React, { useEffect, useRef } from "react";
import { motion, useElementScroll, useReducedMotion } from "framer-motion";
import styled, { createGlobalStyle } from "styled-components";
import Box from "./box";
import ImageBox from "./image-box";
import VideoBox from "./video-box";
import { useDeviceDetect } from "../functions/util";
import LinkExternal from "./link-external";

export default function ScrollLooper({ data, header }) {
  const contextRef = useRef(null);
  const clonesRef = useRef(null);
  const [disableScroll, setDisableScroll] = React.useState(false);
  const { isMobile } = useDeviceDetect();
  const reduceMotion = useReducedMotion();
  const { scrollY } = useElementScroll(contextRef);

  useEffect(() => {
    if (typeof document == `undefined` && typeof window == `undefined`)
      return () => null;

    if (isMobile) return () => null;

    if (reduceMotion) return () => null;

    const context = contextRef.current;

    const updatePosition = setInterval(() => {
      context.scrollTop = context.scrollTop += 1.5;
    }, 15);

    return () => clearInterval(updatePosition);
  });

  useEffect(() => {
    //** Inspired by this codepen https://codepen.io/vincentorback/pen/zxRyzj */
    if (typeof document == `undefined` && typeof window == `undefined`)
      return null;

    const context = contextRef.current;
    const clones = clonesRef.current;

    let scrollHeight = 0;
    let scrollPos = 0;
    let clonesHeight = 0;

    const setScrollPos = (pos) => {
      context.scrollTop = pos;
    };

    const reCalc = () => {
      scrollPos = scrollY.get();
      scrollHeight = context.scrollHeight;
      clonesHeight = clones.offsetHeight;

      if (scrollPos <= 0) {
        setScrollPos(1); // Scroll 1 pixel to allow upwards scrolling
      }
    };

    const scrollUpdate = () => {
      if (!disableScroll) {
        scrollPos = scrollY.get();
        if (clonesHeight + scrollPos >= scrollHeight) {
          setScrollPos(1);
          setDisableScroll(true);
        } else if (scrollPos <= 0) {
          setScrollPos(scrollHeight - clonesHeight);
          setDisableScroll(true);
        }
      }

      if (disableScroll) {
        setTimeout(() => {
          setDisableScroll(false);
        }, 100);
      }
    };

    scrollUpdate();
    reCalc();

    context.addEventListener(
      "scroll",
      () => {
        window.requestAnimationFrame(scrollUpdate);
      },
      100
    );

    window.addEventListener(
      "resize",
      () => {
        window.requestAnimationFrame(reCalc);
      },
      100
    );

    return () => {
      context.removeEventListener(
        "scroll",
        () => {
          window.requestAnimationFrame(scrollUpdate);
        },
        100
      );

      window.removeEventListener(
        "resize",
        () => {
          window.requestAnimationFrame(reCalc);
        },
        100
      );
    };
  }, [scrollY]); // eslint-disable-line

  return (
    <>
      <LoopGlobals />
      <LoopWrapper ref={contextRef}>
        {header && header}
        {data.body.map(({ primary }, i) => (
          <PlayItem key={i} data={primary} />
        ))}
        <div ref={clonesRef}>
          {header && header}
          {data.body.map(({ primary }, i) => {
            if (i <= 1) {
              return <PlayItem key={i} data={primary} />;
            } else return null;
          })}
        </div>
      </LoopWrapper>
    </>
  );
}

function PlayItemContainer({ data, children }) {
  if (data.link.url) {
    return (
      <LoopBox>
        <LinkExternal
          href={data.link.url}
          blank={data.link.target}
          hoverData="project"
        >
          {children}
        </LinkExternal>
      </LoopBox>
    );
  } else {
    return <LoopBox>{children}</LoopBox>;
  }
}

function PlayItem({ data }) {
  if (data.image) {
    return (
      <PlayItemContainer data={data}>
        <ImageBox className="image" fluid={data.image.fluid} />
        {data.caption && <h6>{data.caption}</h6>}
      </PlayItemContainer>
    );
  } else if (data.video) {
    return (
      <PlayItemContainer data={data}>
        <VideoBox className="video" video={data.video} />
        {data.caption && <h6>{data.caption}</h6>}
      </PlayItemContainer>
    );
  } else return null;
}

export const LoopGlobals = createGlobalStyle`
  html,
  body {
    height: 100%;
    overflow: hidden;
    scroll-behavior: initial;
  }
`;

export const LoopBox = styled(Box)`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100vw;
  margin: 3rem 0;

  @media ${(props) => props.theme.device.tablet} {
    height: 45vw;
    margin: 4rem 0;
  }

  a {
    height: 100%;

    &::before,
    &::after {
      visibility: hidden;
    }
  }

  h6 {
    align-self: flex-start;
    margin: 2rem 2rem;
  }

  .image,
  .image .gatsby-image-wrapper,
  .video,
  .video video {
    height: 100%;
  }

  img,
  video {
    object-fit: contain !important;
  }
`;

export const LoopWrapper = styled(motion.div)`
  position: relative;
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;
  width: 100%;

  ${LoopBox}:nth-child(even) {
    video,
    img {
      object-position: left !important;
    }
  }

  ${LoopBox}:nth-child(odd) {
    video,
    img {
      object-position: right !important;
    }

    h6 {
      align-self: flex-end;
    }
  }
`;
